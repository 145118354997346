<template>
  <b-badge
    pill
    :variant="`light-${status ? 'primary' : 'danger'}`"
  >
    {{ status ? 'แสดงข้อมูล' : 'ไม่แสดงข้อมูล' }}
  </b-badge>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },

  props: {
    status: {
      type: [Boolean, Number],
      required: true,
    },
  },
}
</script>

<style>

</style>
