<template>
  <div>
    <popup-remove
      title="ประเภทผู้ใช้งาน"
      url="/MemberType/Remove"
      column="member_type_id"
      :remove-id="dataPopup.member_type_id"
      :remove-name="dataPopup.membertype_desc"
      @fetch-data="fncFetchDataTable"
    />
    <popup-detail :data="dataPopupDetail" />
    <div class="mb-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <button-add url="member_type/form" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <per-page-option
            :per-page.sync="perPage"
          />
        </b-col>
      </b-row>
    </div>
    <b-card no-body>
      <input-search :search.sync="searchQuery" />
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
        show-detail
      />
      <table-simple
        :overlay-load-data-table="overlayLoadDataTable"
        :table-title="tableTitle"
        :total-data="dataTable.length"
      >
        <template slot="data">
          <b-tr
            v-for="(item, index) in dataTable"
            :key="index"
          >
            <b-td>
              {{ item.order }}
            </b-td>
            <b-td>
              {{ item.membertype_desc }}
            </b-td>
            <b-td>
              {{ item.membertype_desc_en }}
            </b-td>
            <b-td>
              <label-status :status="item.status" />
            </b-td>
            <b-td>
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-detail
                  @click="dataPopup = item"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">รายละเอียด</span>
                </b-dropdown-item>

                <b-dropdown-item :to="{ name: 'member-type-form-edit', params: { id: item.member_type_id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">แก้ไข</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-remove
                  @click="dataPopup = item"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">ลบข้อมูล</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-td>
          </b-tr>
        </template>
      </table-simple>
      <pagination
        :item-local-to="itemLocalTo"
        :item-local-count="itemLocalCount"
        :total-data="totalData"
        :current-page.sync="currentPage"
        :per-page="perPage"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTr, BTd, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ButtonAdd from '../components/ButtonAdd.vue'
import PerPageOption from '../components/PerPageOption.vue'
import InputSearch from '../components/InputSearch.vue'
import TableSimple from '../components/TableSimple.vue'
import LabelStatus from '../components/LabelStatus.vue'
import PopupRemove from '../components/PopupRemove.vue'
import PopupDetail from '../components/PopupDetail.vue'

import useMemberType from './useMemberType'
import Pagination from '../components/Pagination.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTr,
    BTd,
    BDropdown,
    BDropdownItem,

    ButtonAdd,
    PerPageOption,
    InputSearch,
    TableSimple,
    LabelStatus,
    PopupRemove,
    PopupDetail,
    Pagination,
  },

  setup() {
    // variable from import
    const {
      // variable table
      currentPage,
      totalData,
      perPage,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    } = useMemberType()

    return {
      // variable table
      currentPage,
      totalData,
      perPage,
      tableTitle,
      dataTable,
      searchQuery,
      itemLocalTo,
      itemLocalCount,
      dataPopup,

      // variable overlay
      overlayLoadDataTable,

      // variable computed
      dataPopupDetail,

      // function
      fncFetchDataTable,
    }
  },
}
</script>

<style scoped>
.table tbody td {
    vertical-align: middle;
    letter-spacing: 0.5px;
    height: 40px;
}
</style>
