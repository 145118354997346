import {
  ref, watch, onMounted, computed,
} from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMemberType() {
  // Notification
  const toast = useToast()

  // variable table
  const currentPage = ref(1)
  const totalData = ref(1)
  const perPage = ref(10)
  const tableTitle = [
    { title: '#', style: 'min-width: 65px; width:65px;' },
    { title: 'ประเภทผู้ใช้', style: 'min-width: 200px;' },
    { title: 'ประเภทผู้ใช้ (ENG)', style: 'min-width: 200px; width:200px;' },
    { title: 'สถานะ', style: 'min-width: 150px; width:150px;' },
    { title: 'เครื่องมือ', style: 'min-width: 80px; width:80px;' },
  ]
  const searchQuery = ref(null)
  const dataTable = ref([])
  const itemLocalTo = ref(0)
  const itemLocalCount = ref(0)
  const dataPopup = ref({})

  // variable overlay
  const overlayLoadDataTable = ref(false)

  // variable computed
  const dataPopupDetail = computed(() => {
    if (!Object.keys(dataPopup.value).length) return []

    return [
      { title: 'ประเภทผู้ใช้', value: dataPopup.value.membertype_desc },
      { title: 'ประเภทผู้ใช้ (ENG)', value: dataPopup.value.membertype_desc_en },
      { title: 'create_date', value: dataPopup.value.create_date },
      { title: 'create_user', value: dataPopup.value.create_user },
      { title: 'update_date', value: dataPopup.value.update_date },
      { title: 'update_user', value: dataPopup.value.update_user },
    ]
  })

  const fncFetchDataTable = () => {
    overlayLoadDataTable.value = true

    const payload = {
      search: searchQuery.value,
      per_page: perPage.value,
      page: currentPage.value,
    }

    axios
      .get('/MemberType', { params: payload })
      .then(response => {
        const setDataTable = []
        Object.keys(response.data.value.data).forEach(key => {
          const item = response.data.value.data[key]
          setDataTable[key] = item
          setDataTable[key].order = response.data.value.total - key - ((currentPage.value - 1) * perPage.value)
        })
        dataTable.value = setDataTable
        totalData.value = response.data.value.total

        itemLocalTo.value = totalData.value - ((currentPage.value - 1) * perPage.value)
        itemLocalCount.value = totalData.value !== 0 ? itemLocalTo.value - dataTable.value.length + 1 : 0

        overlayLoadDataTable.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาด [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadDataTable.value = false
      })
  }

  onMounted(() => {
    fncFetchDataTable()
  })

  watch([searchQuery, currentPage, perPage], () => {
    fncFetchDataTable()
  })

  return {
    // variable table
    currentPage,
    totalData,
    perPage,
    tableTitle,
    dataTable,
    searchQuery,
    itemLocalTo,
    itemLocalCount,
    dataPopup,

    // variable overlay
    overlayLoadDataTable,

    // variable computed
    dataPopupDetail,

    // function
    fncFetchDataTable,
  }
}
